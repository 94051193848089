import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { useLocation } from "@remix-run/react";
import { type AlertProps } from "@mui/material";

const SUBUSERPATH = "/home/subuser";

type DisplayAlert = {
  title: string;
  message: string;
  props?: Omit<AlertProps, "children" | "onClose">;
}

type ViewContextState = {
  viewState: boolean;
  headerHeight: number;
  openSnackbar: boolean;
  alertProps: DisplayAlert;
  openDrawer: boolean;
}

type ViewContextValues = ViewContextState & {
  handleViewChange: (state: boolean) => void;
  handleHeaderHeight: (height: number) => void;
  handleAlert: (title: string, message: string, props?: Omit<AlertProps, "children" | "onClose">) => void;
  handleCloseAlert: () => void;
  handleDrawer: (state: boolean) => void;
}

const ViewStateContext = createContext<ViewContextValues | undefined>(undefined);

export function useViewContext() {
  const context = useContext(ViewStateContext)
  if (!context) {
    throw new Error('viewStateContext must be used within a ViewStateProvider')
  }
  return context
}

type State = ViewContextState;
type Action =
  { type: "SET_VIEW_STATE", payload: boolean } |
  { type: "SET_HEADER_HEIGHT", payload: number } |
  { type: "SET_OPEN_SNACKBAR", payload: DisplayAlert } |
  { type: "SET_CLOSE_SNACKBAR" } |
  { type: "SET_OPEN_DRAWER", payload: boolean };


const viewStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_VIEW_STATE":
      return { ...state, viewState: action.payload };
    case "SET_HEADER_HEIGHT":
      return { ...state, headerHeight: action.payload };
    case "SET_OPEN_SNACKBAR":
      return { ...state, openSnackbar: true, alertProps: action.payload };
    case "SET_CLOSE_SNACKBAR":
      return { ...state, openSnackbar: false, alertProps: { title: "", message: "" } };
    case "SET_OPEN_DRAWER":
      return { ...state, openDrawer: action.payload };
    default:
      return state;
  }
}

export function ViewStateProvider({ children }: any) {
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(viewStateReducer, {
    viewState: false,
    headerHeight: 0,
    openSnackbar: false,
    alertProps: { title: "", message: "" },
    openDrawer: false,
  });


  const handleViewChange = useCallback((state: boolean) => {
    dispatch({ type: "SET_VIEW_STATE", payload: state });
  }, []);

  const handleHeaderHeight = useCallback((height: number) => {
    dispatch({ type: "SET_HEADER_HEIGHT", payload: height });
  }, []);

  const handleAlert = useCallback((title: string, message: string, props?: Omit<AlertProps, "children" | "onClose">) => {
    dispatch({ type: "SET_OPEN_SNACKBAR", payload: { title, message, props } });
  }, []);

  const handleCloseAlert = useCallback(() => {
    dispatch({ type: "SET_CLOSE_SNACKBAR" });
  }, []);

  const handleDrawer = useCallback((state: boolean) => {
    dispatch({ type: "SET_OPEN_DRAWER", payload: state });
  }, []);


  useEffect(() => {
    dispatch({ type: "SET_VIEW_STATE", payload: pathname === SUBUSERPATH ? false : true });
    dispatch({ type: "SET_OPEN_DRAWER", payload: false });
  }, [pathname])

  return (
    <ViewStateContext.Provider
      value={{
        ...state,
        handleViewChange,
        handleHeaderHeight,
        handleAlert,
        handleCloseAlert,
        handleDrawer
      }}
    >
      {children}
    </ViewStateContext.Provider>
  );
}


